<template>
<div>
  <van-nav-bar
      class="backBox"
      title="办理长租"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />

  <div class="page">

    <div class="mainWrap">
      <div class="location_box">
        <div class="location_tit">
          <van-icon name="location-o" color="#4882FC" />
          <span class="location_name">杭州滨江天街</span>
        </div>
        <span class="modify_text">修改</span>
      </div>
      <div class="plate_num_box">
        <!-- <h3 style="text-align: left">请输入车牌号</h3> -->
        <div class="plate_num_box_tit" style="text-align: left">请输入车牌号</div>

        <div class="plate_num_box_input">
          <div
            class="plate_wrap"
            v-for="(item, index) in plateNumber"
            :key="index"
            @click="bindFocus(index)"
          >
            <div
              class="plate_wrap_input"
              v-if="index !== 7"
              :class="[input_index === index ? ' section-dashed' : '']"
            >
              {{ item }}
            </div>
            <!-- 2 -->
            <div
              class="plate_wrap_input new_input"
              v-if="index === 7"
              :class="[input_index == index ? ' section-dashed' : '']"
            >
              <div v-if="newFlag" placeholder="">
                {{ item }}
              </div>
              <img
                v-if="!newFlag"
                src="../../../assets/img/indexnewnengyuan@2x.png"
                alt=""
              />

              <span v-if="!newFlag" class="new_vh">新能源</span>
            </div>
          </div>
        </div>
      </div>

      <div class="month_type_box">
        <van-radio-group  v-model="radioSelVal">
          <div class="month_type">租赁类型</div>
          <div class="" v-for="item in monthTypeList" :key="item.id">
            <div class="month_item">
            <div class="month_item_tit">
              <span class="month_item_tit_name">{{item.name}}</span>
              <span class="month_item_tit_date">生效时间：{{item.date}}</span>
            </div>
            <div class="month_money">
              <span class="month_money_text">
                {{item.value}}元
                </span>
              <label>

                <van-radio :name="item.id" @click="radioBtn(item)"></van-radio>

              </label>
            </div>
            </div>
              <div class="lineline"></div>
          </div>

        </van-radio-group>
      </div>

      <div class="payNumBox">
        <div class="pay_num_tit">
          <span class="pay_num_text">购买数量</span>
          <van-stepper v-model="monthNum" @change="onChangeStep" />
        </div>
        <div class="lineline"></div>
      </div>

      <div class="tip_box">
        <span class="tip_box_tit">温馨提示：</span>
        <span class="tip_box_text"
          >1.套餐显示为单个车位的价格，支付总金额按照本次办理的约束<br />或者周数叠加计算</span
        >
      </div>
      <div class="footer_box">
        <div class="agree_box">
          <van-checkbox
          class="agree_box_checkbox"
            v-model="agreeChecked"
            @change="onChangeCheckBox"

          ></van-checkbox>

          <div class="agree_box_tit">
            <span class="agree_box_tit_text">已经阅读并同意</span>
            <span class="agree_box_tit_name" @click="view_policy"
              >《地下停车场车位租赁服务协议》</span
            >
          </div>
        </div>
        <div class="lineline"></div>
        <div class="pay_box">
          <div class="pay_box_wrap">
            <span class="pay_box_all">合计：</span>
            <span class="pay_box_all_money">{{waitPayMoney.toFixed(2)}}</span>
            <span class="pay_box_all_last">元</span>
          </div>

           <van-button
              class="pay_button"
              type="info"
              color="##4470FC"
              @click="onClickPay"
              >支付</van-button
            >
        </div>
      </div>
    </div>

    <!-- 键盘 -->
    <car-keyboard
      :show.sync="showKeyboard"
      @getResult="setNumber"
      :plateNumber.sync="plateNumber"
      :index.sync="input_index"
      @setIndex="setIndex"
    ></car-keyboard>

    <!-- 隐私弹窗 -->
    <privacy-policy
      :show="wang_show_privacy_policy"
      @get-cancel="getPolicyCancel"
      @get-success="getPolicySuccess"
      @get-polocy-show="getPolicyShow"
    ></privacy-policy>
  </div>
  </div>
</template>
<script>
import CarKeyboard from '../../../components/CarKeyboard.vue'
import PrivacyPolicy from '../../../components/PrivacyPolicy.vue'

// import { Toast } from 'vant'
// import * as constant from '../api/constant'
// import * as common from '../api/common'
// import * as util from '../api/util'
export default {
  components: { CarKeyboard, PrivacyPolicy },
  data () {
    return {
      showKeyboard: false,
      plateNumber: ['', '', '', '', '', '', '', ''], // 键盘操作结果值
      plateNumberBak: ['', '', '', '', '', '', '', ''],
      input_index: 0,
      newFlag: false,
      index: '',
      monthTypeList: [
        {
          id: 1,
          value: 1,
          name: '无限制包月卡',
          date: '全时段'

        },
        {
          id: 2,
          value: 2,
          name: '工作日白天包月卡',
          date: '工作日（周一至周五）08:00至17:00'
        },
        {
          id: 3,
          value: 99,
          name: '夜间包月卡',
          date: '22:00至次日09:00'
        }
      ],
      radioSelVal: null,
      privacy_policy_checked: false,
      wang_show_privacy_policy: false,
      agreeChecked: false,
      monthNum: 1,
      waitPayMoney: 0.00

    }
  },
  mounted () {
    if (this.monthTypeList.length > 0) {
      this.radioSelVal = this.monthTypeList[0].id
      this.countMoney(this.monthTypeList[0], this.monthNum)
    }
  },
  computed: {

  },
  methods: {
    // 计算需支付总金额
    countMoney (data, num) {
      console.log('data', data.value, num)
      this.waitPayMoney = data.value * num
    },
    // 购买数量
    onChangeStep () {
      console.log('购买数量', this.monthNum, this.radioSelVal)
      this.countMoney(this.monthTypeList[Number(this.radioSelVal) - 1], this.monthNum)
    },
    onClickPay () {

    },
    // 切换月租类型
    radioBtn (item) {
      console.log('当前选择的是哪个月租服务', this.radioSelVal, item)

      this.countMoney(item, this.monthNum)
    },
    onChangeCheckBox () {
      console.log('agreeChecked', this.agreeChecked)
    },
    doQuery () {

    },
    bindFocus (index) {
      if (!this.showKeyboard) {
        this.showKeyboard = true
        this.input_index = Number(index)
      } else {
        this.input_index = Number(index)
      }
    },
    setNumber (data) {
      if (data[7] === '') {
        this.newFlag = false
      } else {
        this.newFlag = true
      }
    },
    setIndex (data) {
    },
    clickHistoryNum (data) {
      console.log('点击的历史记录车牌为', data)
      console.log('正在展示的车牌为', this.plateNumber)
      const plateNum = data
      // 如果点击的车牌号和输入框的不一样 则填入  否则 什么也不做
      const inpNum = this.plateNumber.join('')
      if (plateNum === inpNum) {
        return
      }
      const nowArr = plateNum.split('')
      console.log(nowArr)

      const bakArr = JSON.parse(JSON.stringify(this.plateNumberBak))
      console.log(bakArr)
      for (let i = 0; i < nowArr.length; i++) {
        if (nowArr[i] !== '') {
          bakArr[i] = nowArr[i]
        }
      }
      this.plateNumber = bakArr
      console.log('daa', this.plateNumber)
      this.setNumber(this.plateNumber)
      // console.log('整理后的车牌号', bakArr)
      // this.setData({
      //   plateNumber: bakArr,
      //   plateNumQry: plateNum,
      //   historyFlag: true
      // })
      // const that = this
      // is_show(that, plateNum)
      // this.inputKeys(plateNum, 'inputChange')
      // console.log('点击历史后当前车牌字符串', this.data.plateNumQry)
    },
    view_policy () {
      console.log('查看隐私政策')
      this.wang_show_privacy_policy = true
    },
    getPolicyCancel (data) {
      this.wang_show_privacy_policy = false
      this.agreeChecked = false
    },
    getPolicySuccess (data) {
      this.wang_show_privacy_policy = false
      this.agreeChecked = true
    },
    getPolicyShow () {
      this.wang_show_privacy_policy = false
    },
    onClickLeft () {
      this.$router.go(-1)
    }

  }
}
</script>
<style lang="scss" scoped>
.mainWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .location_box {
    width: 100%;
    background-color: rgba(250, 250, 250, 1);
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: .08rem;
    .location_tit {
      // width: 5.5625rem;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      .van-icon{
        font-size: 0.14rem;
        line-height: 0.14rem;
      }
      .location_name {
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 0.12rem;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: .15rem;
      }
    }
    .modify_text {
      overflow-wrap: break-word;
      color: rgba(72, 130, 252, 1);
      font-size: 0.12rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 0.17rem;
      margin-left: 0.04rem;
    }
  }
  .plate_num_box {
    width: 90%;
    // height: 12.5rem;
    background-color: #fff;
    box-shadow: 0px 0.01rem 0.05rem 0px rgba(68, 112, 252, 0.37);
    background-color: rgba(253, 254, 255, 1);
    border-radius: 0.08rem;
    margin-left: 0.01rem;
    display: flex;
    flex-direction: column;
    // padding: 0 0 1.0625rem;
    padding: 0.08rem;
     .plate_num_box_tit{
          font-size: 0.16rem;
          text-align: left;
          font-weight: bolder;
          margin: 0.09rem;

        }
    .plate_num_box_input {
      width: 3.24rem;
      margin-top: 0.11rem;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      padding: 0 0.08rem 0 0.08rem;
      .plate_wrap {
        // width: 2.125rem;
        width: 0.34rem;
        height: 0.44rem;
        background-color: rgba(233, 238, 255, 1);
        display: flex;
        align-items: center;
        text-align: center;
        .section-dashed {
          height: 0.44rem;
          border: 0.01rem solid #1989fa;
        }

        .plate_wrap_input {
          height: 0.44rem;
          width: 0.34rem;
          text-align: center;
          font-size: 0.16rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .new_vh {
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 0.1rem;
            font-family: PingFangSC-Regular;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
            line-height: 0.14rem;
            margin: 0.01rem 0 0 -0.01rem;
          }
        }
        .new_input {
          background-color: rgba(233, 255, 235, 1);
          img {
            width: 0.18rem;
            height: 0.17rem;
            margin: 0 0.06rem 0 0.05rem;
          }
        }
      }
    }
    .history {
      margin-top: 0.12rem;
      display: flex;
      justify-content: flex-start;
      // flex-wrap: wrap;
      align-items: center;
      .historyIcon {
        margin-left: 0.08rem;
        margin-bottom: 0.08rem;
      }
      .historyItem {
        background-color: #f4f4f4;
        padding: 0.05rem 0.09rem;
        border-radius: 0.12rem;
        font-size: 0.1rem;
        color: #333;

        margin-right: 0.04rem;
        margin-left: 0.08rem;
        margin-bottom: 0.08rem;
      }
    }
    .query_btn {
      background-color: rgba(68, 112, 252, 1);
      border-radius: 0.04rem;
      margin-top: 0.12rem;
      display: flex;
      flex-direction: column;
      padding: 0.08rem 1.21rem 0.08rem 1.21rem;
      .query_btn_text {
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 0.16rem;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 0.22rem;
      }
    }
  }
  .month_type_box {
    width: 3.43rem;
    align-self: center;
    margin-top: 0.14rem;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    .month_type{
overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 0.16rem;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          white-space: nowrap;
          line-height: 0.22rem;
          margin: 0 0.23rem 0 0.09rem;
    }
    .month_item {
      width: 3.43rem;
      align-self: center;
      margin-top: .14px;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      .month_item_tit {
        display: flex;
        flex-direction: column;
        .month_item_tit_type {

        }
        .month_item_tit_name {
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 0.14rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.2rem;
          margin: 0.09rem 0.12rem 0 0;
        }
        .month_item_tit_date {
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 0.12rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.17rem;
          margin-top: 0.02rem;
        }
      }
      .month_money {
        // width: 4.6875rem;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0.31rem 0 0.19rem 0;
        .month_money_text {
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 0.14rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.14rem;
          margin-right: 0.02rem;
        }
      }
    }
  }
  .payNumBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .pay_num_tit {
      width: 3.43rem;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.04rem 0.1rem 0.04rem 0.1rem;
      .pay_num_text{
        font-size: 0.12rem;
        color: #999999;
      }
    }
  }
  .tip_box {
    display: flex;
    flex-direction: column;
    margin: 0.1rem 0.27rem 0 0.16rem;
    .tip_box_tit {
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 0.12rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 0.17rem;
      margin-right: 2.77rem;
    }
    .tip_box_text {
      width: 3.32rem;
      height: 0.34rem;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 0.12rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      line-height: 0.17rem;
      margin-top: 0.08rem;
    }
  }
  .footer_box {
    box-shadow: 0 -0.02rem 0.02rem 0 rgba(239, 239, 239, 0.5);
    background-color: rgba(255, 255, 255, 1);
    margin-top: 0.08rem;
    display: flex;
    flex-direction: column;
    padding: 0.09rem 0 0.08rem 0;
    .agree_box {
      width: 2.64rem;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0.95rem 0.1rem 0.16rem;
      // /deep/ .agree_box_checkbox{
      //   font-size: 0.2rem !important;
      // }
     /deep/ .van-checkbox__icon,.van-checkbox__icon--round{
       font-size: 0.2rem !important;
      }
      .agree_box_tit {
        width: 2.42rem;
        height: 0.16rem;
        overflow-wrap: break-word;
        font-size: 0.16rem;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 0.16rem;

        .agree_box_tit_text {
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 0.11rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.16rem;
          margin-left: 0.06rem;
        }
        .agree_box_tit_name {
          overflow-wrap: break-word;
          color: rgba(68, 112, 252, 1);
          font-size: 0.11rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.16rem;
        }
      }
    }
    .pay_box {
      // width: 13.875rem;
      // flex-direction: row;
      display: flex;
      justify-content: flex-end;
      margin-top: 0.08rem;
      .pay_box_wrap {
        // width: 5.4375rem;
        height: 0.25rem;
        overflow-wrap: break-word;
        font-size: 0.11rem;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: left;
        line-height: 0.17rem;
        margin-top: 0.21rem;
        margin-right: 0.16rem;
        .pay_box_all {
          height: 0.25rem;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 0.12rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          line-height: 0.17rem;
        }
        .pay_box_all_money {
          height: 0.25rem;
          overflow-wrap: break-word;
          color: rgba(68, 112, 252, 1);
          font-size: 0.18rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.17rem;
        }
        .pay_box_all_last {
          height: 0.25rem;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 0.12rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          line-height: 0.17rem;
        }
      }
      .pay_button {
        // background-color: rgba(68, 112, 252, 1);
        border-radius: 0.04rem;
        display: flex;
        flex-direction: column;
        padding: 0.11rem 0.43rem 0.11rem 0.43rem;
        margin-right: 0.16rem;

      }
    }
  }
}

.lineline {
  width: 3.43rem;
  height: 0.01rem;
  background-color: #f4f4f4;
}
</style>
